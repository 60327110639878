class FileUpload {
    constructor(dropZone) {
        this.dropZone = dropZone;
        this.clearTrigger = dropZone.querySelector('.js-file-upload-clear');
        this.input = dropZone.querySelector('.js-file-upload-input');
        this.label = dropZone.querySelector('.js-file-upload-label');

        this.updateLabel();
        this.registerEventListeners();
    }

    registerEventListeners() {
        this.dropZone.addEventListener('dragover', FileUpload.handleDragOver, false);
        this.dropZone.addEventListener('dragenter', this.handleDragEnter.bind(this), false);
        this.dropZone.addEventListener('dragleave', this.handleDragLeave.bind(this), false);
        this.dropZone.addEventListener('drop', this.handleDrop.bind(this), false);

        this.input.addEventListener('change', this.updateLabel.bind(this), false);

        this.clearTrigger.addEventListener('click', this.handleClear.bind(this), false);
    }

    static handleDragOver(event) {
        event.preventDefault();
    }

    handleDragEnter(event) {
        event.preventDefault(); // IE11
        this.dropZone.classList.add('dragover');
    }

    handleDragLeave() {
        this.dropZone.classList.remove('dragover');
    }

    handleDrop(event) {
        event.preventDefault();
        this.handleDragLeave();

        this.input.files = event.dataTransfer.files;
        this.updateLabel();
    }

    handleClear() {
        this.input.value = null;
        this.updateLabel();
    }

    updateLabel() {
        let label = '';

        if (this.input.files.length) {
            label = this.input.files[0].name;
            this.clearTrigger.classList.remove('hidden');
        } else if (this.label.dataset.defaultText) {
            label = this.label.dataset.defaultText;
            this.clearTrigger.classList.add('hidden');
        }

        this.label.innerHTML = label;
    }
}

window.addEventListener('load', () => {
    const [...elements] = document.querySelectorAll('.js-file-upload');

    if (!elements.length) return;

    this.fileUpload = elements.map((element) => new FileUpload(element));
});
